import React from "react";
import TitleName from "./TitleName";

const CarDetailingFaq = () => {
  return (
    <div className="container mt-3">
      <TitleName title="Frequently Asked " subtitle="Questions (FAQs)" />
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h3 className="fs-5">
                How often should I get my car detailed in Delhi?
              </h3>
            </button>
          </h3>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                In Delhi, it’s a good idea to get your car detailed every 4 to 6
                months. This helps protect it from the city’s dust and pollution
                and keeps it looking great.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <h3 className="fs-5">
                {" "}
                How does Delhi's pollution affect my car’s detailing needs?
              </h3>
            </button>
          </h3>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Delhi’s pollution can cause dirt and grime to build up quickly.
                Regular detailing helps remove these pollutants and protects
                your car’s paint and interior from damage.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h3 className="fs-5">
                Do I need to make an appointment for car detailing in Delhi?
              </h3>
            </button>
          </h3>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Yes, it’s best to make an appointment to ensure we can fit you
                into our schedule. Contact us to book a convenient time for your
                car detailing.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">
                {" "}
                What should I do before bringing my car in for detailing?
              </h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Before your appointment, please remove any personal items from
                the interior of your car. This allows us to clean all areas
                thoroughly and efficiently.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5"> How long does a detailing service take?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                The time required for detailing depends on the service package
                you choose and the condition of your car. On average, it can
                take anywhere from 2 to 4 hours.
              </p>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default CarDetailingFaq;
