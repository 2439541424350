import React from "react";
import TitleName from "./TitleName";

const CeramicFaq = () => {
  return (
    <div className="container mt-3">
      <TitleName title="Frequently Asked " subtitle="Questions (FAQs)" />
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h3 className="fs-5">How Long Does Ceramic Coating Last?</h3>
            </button>
          </h3>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Our ceramic coatings typically last several years with proper
                care.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <h3 className="fs-5">Can Ceramic Coating be Removed?</h3>
            </button>
          </h3>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">Yes, but it requires professional removal.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h3 className="fs-5">
                Does Ceramic Coating Affect the Car’s Appearance?
              </h3>
            </button>
          </h3>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Yes, it enhances the appearance with a deep, glossy finish.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">Is Ceramic Coating Worth the Investment?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Absolutely, it offers long-term protection and enhances your
                car’s value.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CeramicFaq;
