import React from "react";
import TitleName from "./TitleName";
import "./CommonCarDetailing.css";
import handcoating from "../../asets/Rectangle 1.png";
import handcoating1 from "../../asets/Rectangle 2.png";
import handcoating2 from "../../asets/Rectangle 3.png";

const BenfitsData = () => {
  return (
    <div className="mt-3">
      <div
        class="card w-100 p-3"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "none",
        }}
      >
        <div className="p-4">
          <h3 class="card-title text-danger fw-bold">Benefits</h3>
          <p className="fs-5 mt-2">
            <strong>Keeps Your Car Looking New:</strong> Detailing removes dirt,
            scratches, and stains, making your car look fresh and well-kept.
            Regular detailing helps maintain that new-car shine, even as your
            vehicle ages.
          </p>
          <p className="fs-5 mt-3">
            <strong>Protects Your Car’s Paint:</strong> Over time, your car’s
            paint can get damaged by sun exposure, dirt, and other elements.
            Detailing adds a protective layer that shields the paint from
            damage, helping it last longer and look better.
          </p>
          <p className="fs-5 mt-3">
            <strong>Improves Interior Comfort:</strong> A clean interior makes
            your car a more comfortable place to be. Detailing services clean
            the seats, carpets, and dashboard, removing dust, stains, and bad
            smells, which improves the overall driving experience.
          </p>
          <p className="fs-5 mt-3">
            <strong>Increases Resale Value:</strong> A well-maintained car is
            worth more when you decide to sell it. Regular detailing keeps both
            the exterior and interior in top shape, which can help you get a
            better price when selling.
          </p>
          <p className="fs-5 mt-3">
            <strong>Prevents Long-Term Damage:</strong> Dirt, grime, and
            contaminants can cause wear and tear on your car over time.
          </p>
          <p className="fs-5 mt-3">
            <strong>Enhances Safety:</strong> Clean headlights, mirrors, and
            windows improve visibility while driving. Detailing ensures that all
            these parts are spotless, contributing to safer driving conditions.
          </p>
        </div>
      </div>
    </div>
  );
};

const BenifitCarDetailing = () => {
  return (
    <div className="container mb-3">
      <TitleName title="BENEFITS OF" subtitle="Car Detailing Services" />
      <div className="row">
        <div className="col-md-7">
          <img
            className="w-100"
            src={handcoating}
            alt="Ceramic Coating for Cars"
          />
        </div>
        <div className="col-md-5">
          <img
            // style={{ width: "470px", height: "292px" }}
            className="w-100 mt-1"
            src={handcoating2}
            alt="Ceramic Coating Application"
          />
          <img
            // style={{ width: "475px", height: "292px" }}
            className="w-100 mt-3"
            src={handcoating1}
            alt="Ceramic Coating Finishing"
          />
        </div>
      </div>
      <BenfitsData />
    </div>
  );
};

export default BenifitCarDetailing;
