import React from "react";
import PaintProtection from "./PaintProtection";
import BenefitsOfPaintProtection from "./BenefitsOfPaintProtection";
import InstallationProcess from "./InstallationProcess";
import ThePPFInstallationProcess from "./ThePPFInstallationProcess";
import Testimonials from "./Testimonials";
import InstagramImages from "./InstagramImages";
import ContactUs from "../../homepage/contact-us/ContactUs";
import FAQ from "./FAQ";
import { Helmet } from "react-helmet";
const PaintProtectionFilmDelhi = () => {
  return (
    <>
      <Helmet>
        <title> Best quality paint protection film coating in Delhi | Caross</title>
        <meta
          name="description"
          content="  Discover the best PPF coating for your car in Delhi. Our high-quality paint protection services ensure lasting durability & flawless finish for your vehicle."
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "How Long Does PPF Last?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "With proper care, PPF can last between 5 to 10 years, providing long-term protection for your vehicle.",
                },
              },
              {
                "@type": "Question",
                name: "Can PPF be Removed?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, PPF can be safely removed without damaging your car's paint, allowing you to replace it if necessary.",
                },
              },
              {
                "@type": "Question",
                name: "Does PPF Affect the Car's Appearance?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "PPF is transparent and designed to be nearly invisible, maintaining your car's original appearance while offering protection.",
                },
              },
              {
                "@type": "Question",
                name: "Is PPF Worth the Investment?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Absolutely! PPF protects your car's paint, reduces maintenance costs, and preserves resale value, making it a smart investment.",
                },
              },
            ],
          }`}
        </script>
      </Helmet>
      <div className="container-fluid" style={{ backgroundColor: "#F2F2F2" }}>
        <div className="container">
          <PaintProtection />
          <BenefitsOfPaintProtection />
          <InstallationProcess />
          <ThePPFInstallationProcess />
          <FAQ />
          <Testimonials />
          <InstagramImages />
          <ContactUs />
        </div>
      </div>
    </>
  );
};

export default PaintProtectionFilmDelhi;
