import React from "react";
import WhatCoating from "./WhatCarDetailing";
import ContactUs from "../homepage/contact-us/ContactUs";
import { Helmet } from "react-helmet";
import BenifitCarDetailing from "./BenifitCarDetailing";
import WeOfferSection from "./WeOfferSection";
import WhyWEChooseCardDetailing from "./WhyWEChooseCardDetailing";
import CarDetailingFaq from "./CarDetailingFaq";

const CarDetailingServices = () => {
  return (
    <>
      <Helmet>
        <title>Top Car Detailing Services for a Pristine Finish | Caross</title>
        <meta
          name="description"
          content=" Caross offers top-quality car detailing services that clean and restore your vehicle inside and out. Book your appointment for a shiny, and well-maintained car."
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "How Long Does Ceramic Coating Last?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Our ceramic coatings typically last several years with proper care.",
                },
              },
              {
                "@type": "Question",
                name: "Can Ceramic Coating be Removed?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, but it requires professional removal.",
                },
              },
              {
                "@type": "Question",
                name: "Does Ceramic Coating Affect the Car's Appearance?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, it enhances the appearance with a deep, glossy finish.",
                },
              },
              {
                "@type": "Question",
                name: "Is Ceramic Coating Worth the Investment?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Absolutely, it offers long-term protection and enhances your car’s value.",
                },
              },
            ],
          }`}
        </script>
      </Helmet>
      <div className="p-2">
        <h1 className="text-center fw-bold" style={{ marginTop: "5rem" }}>
          Car <span style={{ color: "#b8242a" }}> Detailing Services </span>
        </h1>
        <WhatCoating />
        <BenifitCarDetailing />
        <WeOfferSection />
        <WhyWEChooseCardDetailing />
        <CarDetailingFaq />
        
        

        <ContactUs />
      </div>
    </>
  );
};

export default CarDetailingServices;
