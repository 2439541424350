import React from "react";
import "./Common.css";

const InstallationProcess = () => (
  <div className="container">
    <div className="installation-process">
      <h2 className="text-center text-danger mt-4 fw-bold text-uppercase">
        Why Choose Us for PPF Installation in Gurgaon?
      </h2>
      <div className="row mt-4">
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step purchase-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>1.Expert Technicians</h3>
            <p>
              Our team of skilled professionals has extensive experience in PPF
              installation, ensuring your vehicle receives the best possible
              care. We use only the highest quality films from leading
              manufacturers to guarantee superior results.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step call-scheduled-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>2. Advanced Facility</h3>
            <p>
              Our Gurgaon facility is equipped with the latest tools and
              technology for PPF application. We maintain a clean and controlled
              environment to ensure flawless installation, free from dust and
              contaminants.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step install-test-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>3. Customer Satisfaction Guarantee</h3>
            <p>
              We are committed to delivering exceptional service and ensuring
              complete customer satisfaction.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step finished-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>4. Affordable Packages</h3>
            <p>
              We offer competitive pricing on all our PPF services in Gurgaon,
              with packages designed to meet various needs and budgets. Whether
              you’re looking for full-body protection or specific areas, we
              provide value without compromising on quality.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InstallationProcess;
