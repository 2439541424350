import React from "react";
// import MultipleBenfits from "./MultipleBenfits";
import paintProtectionTwo from "../../../asets/paintProtectionTwo.png"; // You can use different images for each section
import TitleName from "./TitleName";

const MultipleBenfits = ({ title, description }) => {
  return (
    <>
      <div className="row mb-4">
        <div className="col-12 d-flex align-items-center">
          <img
            src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcS2hn5E_L2a0vLEVivGh4ao22fpOa4Uro4EQ9Ayvf6PPuWZveXT"
            alt=""
            className="img-fluid me-3"
            style={{
              width: "1.5rem",
              height: "1.5rem",
              objectFit: "contain",
            }}
          />
          <p className="fw-bold mb-0">{title}</p>
        </div>
        <div className="col-11">
          <p className="mx-3">{description}</p>
        </div>
      </div>
    </>
  );
};


const WeOfferSection = () => {
  // Data for all sections
  const sections = [
    {
      title: "Exterior Detailing",
      imgSrc: paintProtectionTwo,
      benefits: [
        {
          title: "Hand Wash & Dry",
          description: "We carefully wash your car by hand to remove dirt and grime without causing any scratches.",
        },
        {
          title: "Clay Bar Treatment",
          description: "This step removes contaminants that regular washing can’t, leaving your car’s paint smooth and clean.",
        },
        {
          title: "Polishing & Waxing",
          description: "Polishing brings back the shine of your car’s paint, while waxing adds a protective layer to keep it looking good for longer.",
        },
        {
          title: "Tire & Wheel Cleaning",
          description: "We clean your tires and wheels thoroughly, making them look as good as new.",
        },
      ],
    },
    {
      title: "Interior Detailing",
      imgSrc: paintProtectionTwo, // Replace with the correct image path for interior detailing
      benefits: [
        {
          title: "Vacuuming & Cleaning",
          description: "We vacuum the entire interior, including seats, carpets, and mats, to remove dust and debris.",
        },
        {
          title: "Leather Care",
          description: "For leather interiors, we clean and condition the leather to keep it soft and prevent cracks.",
        },
        {
          title: "Dashboard & Console Care",
          description: "We clean and polish the dashboard, console, and other interior surfaces for a dust-free and polished look.",
        },
        {
          title: "Odor Removal",
          description: "We use special techniques to remove any bad smells, leaving your car smelling fresh.",
        },
      ],
    },
    {
      title: "Foam Wash Benefits",
      imgSrc: paintProtectionTwo, // Replace with the correct image path for foam wash benefits
      benefits: [
        {
          title: "High-Quality Foam Solution",
          description: "Our foam wash uses premium foam that effectively lifts and removes dirt and grime from your car's surface.",
        },
        {
          title: "Deep Cleaning Action",
          description: "The rich foam penetrates deep into the crevices of your car, reaching areas that regular washes might miss.",
        },
        {
          title: "Enhanced Shine",
          description: "After the foam wash, your car will have a brighter and shinier appearance.",
        },
        {
          title: "Interior Vacuum",
          description: "We include a thorough vacuuming of your car’s interior, removing dust, dirt, and debris.",
        },
      ],
    },
  ];

  return (
    <div className="container">
      <TitleName title="WHAT" subtitle="WE OFFER" />
      {sections.map((section, index) => (
        <div className="row mb-5" key={index}>
          {/* Alternate image position: Left for odd index, Right for even index */}
          {index % 2 === 0 ? (
            <>
              <div className="col-md-6 mt-3 d-flex justify-content-center order-md-2">
                <img
                  src={section.imgSrc}
                  alt={section.title}
                  className="img-fluid w-100 mt-3"
                />
              </div>
              <div className="col-md-6 mt-3 order-md-2">
                <h2 className="text-center text-danger mt-4 fw-bold">{section.title}</h2>
                {section.benefits.map((benefit, i) => (
                  <MultipleBenfits
                    key={i}
                    title={benefit.title}
                    description={benefit.description}
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 mt-3 order-md-1">
                <h2 className="text-center text-danger mt-4 fw-bold">{section.title}</h2>
                {section.benefits.map((benefit, i) => (
                  <MultipleBenfits
                    key={i}
                    title={benefit.title}
                    description={benefit.description}
                  />
                ))}
              </div>
              <div className="col-md-6 mt-3 d-flex justify-content-center order-md-2">
                <img
                  src={section.imgSrc}
                  alt={section.title}
                  className="img-fluid w-100 mt-3"
                />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default WeOfferSection;
