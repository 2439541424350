import React from "react";
import TitleName from "./TitleName";

const CeramicFaq = () => {
  return (
    <div className="container mt-3">
      <TitleName title="Frequently Asked " subtitle="Questions (FAQs)" />
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h3 className="fs-5">
                How much does ceramic coating cost in Gurgaon?
              </h3>
            </button>
          </h3>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                The cost of ceramic coating in Gurgaon can vary depending on the
                size of your vehicle, the type of coating used, and the
                detailer's experience. On average, you can expect to pay between
                ₹15,000 and ₹50,000.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <h3 className="fs-5">
                {" "}
                How should I maintain my car after ceramic coating?
              </h3>
            </button>
          </h3>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                {" "}
                After applying a ceramic coating, it's essential to follow
                proper maintenance practices to keep it effective. Avoid washing
                your car for at least a week after the coating is applied.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h3 className="fs-5">
                How often should I reapply ceramic coating in Gurgaon?
              </h3>
            </button>
          </h3>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Given Gurgaon’s challenging environmental conditions, it’s
                recommended to inspect your ceramic coating annually and
                consider reapplication every 2 to 3 years.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">
                {" "}
                What is the difference between ceramic coating and traditional
                waxing in Gurgaon’s context?
              </h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                In a city like Gurgaon, where pollution and harsh weather
                conditions are common, ceramic coating offers longer-lasting
                protection compared to traditional waxing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CeramicFaq;
