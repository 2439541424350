import React from "react";
import "./CommonCarDetailing.css";
import { Link } from "react-router-dom";
// import { FaUsers, FaCheckCircle, FaSearchPlus, FaWallet } from "react-icons/fa"; // Assuming you're using react-icons

const WhyWEChooseCardDetailing = () => (
  <div className="container">
    <div className="installation-process">
      <h2 className="text-center text-danger mt-4 fw-bold uppercase">
        Why Choose Us?
      </h2>
      <div className="row mt-4">
        {/* Experienced Team */}
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div className="installation-process-step purchase-step">
            {/* <FaUsers className="icon-style" /> Icon */}
            <h3>1. Experienced Team</h3>
            <p>
              Our team is trained to handle all types of cars with care and precision.
            </p>
          </div>
        </div>

        {/* Quality Products */}
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div className="installation-process-step call-scheduled-step">
            {/* <FaCheckCircle className="icon-style" /> Icon */}
            <h3>2. Quality Products</h3>
            <p>
              We use high-quality products that are safe for your car and the environment.
            </p>
          </div>
        </div>

        {/* Attention to Detail */}
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div className="installation-process-step install-test-step">
            {/* <FaSearchPlus className="icon-style" /> Icon */}
            <h3>3. Attention to Detail</h3>
            <p>
              We focus on every little detail to make sure your car looks perfect inside and out.
            </p>
          </div>
        </div>

        {/* Flexible Packages */}
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div className="installation-process-step finished-step">
            {/* <FaWallet className="icon-style" /> Icon */}
            <h3>4. Flexible Packages</h3>
            <p>
              We offer different detailing packages to fit your needs and budget.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="d-flex justify-content-center mt-5">
        <Link to="/services/car-detailing">
          <button
            style={{
              backgroundColor: "#b42424",
              color: "#fff",
              padding: "13px 50px",
              fontSize: "15px",
            }}
            className="btn btn mt-5"
          >
            Car Detailing Services
          </button>
        </Link>
      </div>
  </div>
);

export default WhyWEChooseCardDetailing;
