import React from "react";
import TitleName from "./TitleName";

const CeramicFaq = () => {
  return (
    <div className="container mt-3">
      <TitleName title="Frequently Asked " subtitle="Questions (FAQs)" />
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h3 className="fs-5">
                Is ceramic coating worth the investment in Delhi?
              </h3>
            </button>
          </h3>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Given Delhi's harsh climate and pollution levels, ceramic
                coating provides long-term protection that helps maintain your
                car’s appearance and reduces the need for frequent detailing.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <h3 className="fs-5"> How long does ceramic coating last?</h3>
            </button>
          </h3>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">Ceramic coating can last between 2 to 5 years, depending on the quality of the product and how well you maintain your vehicle. Regular upkeep and proper care can help maximize the coating’s lifespan.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h3 className="fs-5">
              How long does the ceramic coating application process take?
              </h3>
            </button>
          </h3>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              The application process typically takes between 1 to 3 days, depending on the vehicle’s condition and the number of coating layers applied. 
              </p>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5">Is Ceramic Coating Worth the Investment?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Absolutely, it offers long-term protection and enhances your
                car’s value.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CeramicFaq;
