import React, { useState } from "react";
import "./Common.css";
import user from "../../asets/user.png"


const testimonials = [
  {
    title: "Hear from Our Satisfied Clients",
    text: "Caross did an amazing job with the PPF on my car. The installation was flawless, and the team was very professional.",
    author: "Rajesh K",
    role: "Author",
    image:
      "https://img.freepik.com/free-photo/young-businessman-with-laptop-computer-working-office_231208-2579.jpg?uid=R140412218&ga=GA1.1.494869419.1717679442&semt=ais_hybrid",
  },
  {
    title: "Real-life Examples of Our Work",
    text: "Check out our gallery of before-and-after photos showcasing the impressive results of our PPF installations.",
    author: "Vinay Kumar",
    role: "Life Coach",
    image:
      "https://img.freepik.com/free-photo/young-pretty-girl-standing-giving-thumbs-up-white_114579-74315.jpg?uid=R140412218&ga=GA1.1.494869419.1717679442&semt=ais_hybrid",
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className="testimonials-container mt-5">
      <h2 className="testimonial-heading">TESTIMONIALS</h2>
      <div className="testimonial-card mt-4">
        <div className="testimonial-text">
          <p>"{testimonials[currentIndex].text}"</p>
          <p>
            <strong>{testimonials[currentIndex].author}</strong>
          </p>
          <p>{testimonials[currentIndex].role}</p>
          <img src={user} class="rounded-circle" alt="profile-img" width="35px" height="35px"/>
          <div className="stars">
            {Array(5)
              .fill()
              .map((_, i) => (
                <span key={i} className="star">
                  ★
                </span>
              ))}
          </div>
        </div>
      </div>
      <div className="testimonial-navigation">
        {/* <button onClick={goToPrevious} className="nav-button">Previous</button> */}
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`nav-dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
        {/* <button onClick={goToNext} className="nav-button">Next</button> */}
      </div>
    </div>
  );
};

export default Testimonials;
