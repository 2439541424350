import React from "react";
import "./CommonCeramic.css";

const WhatCoating = () => {
  return (
    <>
      <div className="mb-2 p-2">
        <div className="row">
          <div className="col-md-5">
            <h2 className="mt-4 fw-bold">
              INTRODUCATION TO{" "}
              <span style={{ color: "#b8242a" }}>CERAMIC COATING</span>{" "}
            </h2>
            <h2 className="fw-bold mt-4">What is Ceramic Coating?</h2>
            <p>
              Ceramic coating is a liquid polymer applied to the exterior of a
              vehicle. It creates a chemical bond with the vehicle's factory
              paint, forming a layer of protection. This advanced protective
              solution enhances the car's durability and maintains its showroom
              shine.
            </p>

            <h2 className="fw-bold mt-4">
              Ceramic Coatings for Gurgaon weather conditions
            </h2>
            <p>
              Ceramic coating provides long-lasting protection against
              environmental contaminants, UV rays, and minor scratches. It also
              simplifies the cleaning process, making maintenance more
              manageable and keeping your car looking pristine.
            </p>
          </div>
          <div className="col-md-5 image-container">
            <img
              className="w-100 mt-2 image-zoom rounded"
              src="https://lirp.cdn-website.com/263ba0d9/dms3rep/multi/opt/ceramic-coating-detail-solution-06-640w.jpg"
              alt="Introduction to Ceramic Coating"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatCoating;
