import React, {  useEffect, useRef, useState } from "react";
import Banner from "./banner/Banner";
import ProfessionalDenting from "./professional-denting/ProfessionalDenting";
import ExperianceIndustry from "./experiance-in-industry/ExperianceIndustry";
import WorkProcess from "./work-process/WorkProcess";
import BestWork from "./bestwork/BestWork";
import Testimonial from "./testimonial/Testimonial";
import Brands from "./brands/Brands";
import DownLoadApp from "./download-app/DownLoadApp";
import ContactUs from "./contact-us/ContactUs";
import FrequentalyAskQuestions from "./frequentaly-ask-questions/FrequentalyAskQuestions";
import Axios from "../../Axios";
//import { AuthContext } from '../../AuthProvider'
import Loader from "../../Loader";
import Toast from "../../Tost";
import { Helmet } from "react-helmet";

function Homepage() {
  const [bannerData, setBannerData] = useState([]);
  const [faqsData, setFaqsData] = useState([]);
  const [iseLoading, setIsLoading] = useState(false);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [grow, setGrow] = useState([]);
  const [workProcess, setWorkProcess] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [proDentPent, setProDentPent] = useState("");
  const [partners, setPartners] = useState([]);
  // const {userToken} = useContext(AuthContext)
  const [allCategoriesData, setAllCategoriesData] = useState([]);
  const childRef = useRef(null);

  const scrollToChild = () => {
    childRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleGetBanner = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get("get_all_banners");
      if (response.status === 200) {
        const data = response?.data;
        setBannerData(data?.banners);
        //  console.log("banner..",data?.banners);
      }
    } catch (err) {
      // const error = err.response.data
      //  Toast(error.message)
    } finally {
      setIsLoading(false);
    }
  };

  const handleFAQS = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get("/get_faqs");
      if (response.status === 200) {
        const data = response?.data;

        setFaqsData(data?.faqs);
        //   console.log("faqs..",data);
      }
    } catch (err) {
      // const error = err.response.data
      //  Toast(error.message)
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleGetBanner();
    handleFAQS();
  }, []);

  const getAllCategories = async () => {
    setIsLoading(true);
    try {
      const response = await Axios("/get_all_categories");
      if (response.status === 200) {
        const data = response?.data;
        //   console.log("categories...", data);
        setAllCategoriesData(data?.categories.reverse());
        // Toast(data?.message,response?.status)
      }
    } catch (err) {
      const error = err.response.data;
      Toast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  // testimonials
  const getTestimonials = async () => {
    setIsLoading(true);
    try {
      const response = await Axios("/get_testimonials");
      if (response.status === 200) {
        const data = response?.data;
        // console.log("testimonials",data);

        setTestimonialsData(data?.testimonials);

        // Toast(data?.message,response?.status)
      }
    } catch (err) {
      const error = err.response.data;
      Toast(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log("test", testimonialsData);
  // experience in industry
  const getExperiencedData = async () => {
    setIsLoading(true);
    try {
      const response = await Axios("/get-stat");
      if (response.status === 200) {
        const data = response?.data;
        setGrow(data?.stats);
        // Toast(data?.message,response?.status)
      }
    } catch (err) {
      const error = err.response.data;
      Toast(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  //console.log("grow", grow);
  // work Process
  const getWorkProcessData = async () => {
    setIsLoading(true);
    try {
      const response = await Axios.get("/work-process");
      if (response.status === 200) {
        const data = response?.data;

        setWorkProcess(data?.workprocess);
        // Toast(data?.message,response?.status)
      }
    } catch (err) {
      const error = err.response.data;
      Toast(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  //  console.log("work process", workProcess);
  // social media
  const getSocialMediaData = async () => {
    setIsLoading(true);
    try {
      const response = await Axios("/gallery");
      if (response.status === 200) {
        const data = response?.data;

        setSocialMediaData(data?.gallery);
        // Toast(data?.message,response?.status)
      }
    } catch (err) {
      const error = err.response.data;
      Toast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBrands = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get("/partners");
      if (response.status === 200) {
        const data = response?.data;
        setBrands(data?.partners);
        //  console.log("banner..",data?.banners);
      }
    } catch (err) {
      // const error = err.response.data
      //  Toast(error.message)
    } finally {
      setIsLoading(false);
    }
  };

  const handlePartners = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get("/get-partners");
      if (response.status === 200) {
        const data = response?.data;
        setPartners(data?.partners);
        //  console.log("banner..",data?.banners);
      }
    } catch (err) {
      // const error = err.response.data
      //  Toast(error.message)
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfessionaldentPent = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get("/who_we_are");
      if (response.status === 200) {
        const data = response?.data;
        setProDentPent(data?.about);
        //  console.log("banner..",data?.banners);
      }
    } catch (err) {
      // const error = err.response.data
      //  Toast(error.message)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTestimonials();
    getSocialMediaData();
    getWorkProcessData();
    getExperiencedData();
    handleBrands();
    handlePartners();
    handleProfessionaldentPent();
  }, []);

  // console.log("testi ", testimonialsData);
  return (
    <>
      <Helmet>
        <title>Welcome to Caross - Detailing Studio</title>

        <meta
          name="description"
          content="Welcome to Caross.in – your premier destination for top-notch auto detailing services. Transform your vehicle with our expert care, enhancing its appearance and preserving its value. Discover the ultimate in automotive aesthetics with Caross.in."
        ></meta>

        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What services do you offer?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We provide a range of post-sales services, including maintenance, repairs, auto-detailing, and more."
    }
  },{
    "@type": "Question",
    "name": "How can I contact your support team?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You can reach our support team by phone (8929227077), email, or through our online contact form."
    }
  },{
    "@type": "Question",
    "name": "Is your team qualified and certified?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our team consists of highly qualified and certified technicians with expertise in servicing a wide range of vehicles."
    }
  },{
    "@type": "Question",
    "name": "Do you service all car makes and models?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, we service a wide range of car makes and models to meet your specific needs."
    }
  },{
    "@type": "Question",
    "name": "Do you offer pickup and drop-off services?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, we provide pickup and drop-off services to make servicing your vehicle more convenient. Contact us to schedule this service."
    }
  },{
    "@type": "Question",
    "name": "Can I request a service appointment for multiple vehicles?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can schedule service appointments for multiple vehicles simultaneously for your convenience. In case of any problem, you can easily reach out to our customer care support (8929227077)."
    }
  },{
    "@type": "Question",
    "name": "Do you offer warranties on your work?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, we offer warranties on our work to ensure your satisfaction and peace of mind."
    }
  },{
    "@type": "Question",
    "name": "Can I track the progress of my service online?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can track the status of your service online through our website or app for real-time updates."
    }
  },{
    "@type": "Question",
    "name": "What if my car is not listed?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If your car is not listed, please contact our customer support team. They can assist you in finding a solution or alternative options to address your specific needs. We are committed to accommodating a wide range of vehicles and will do our best to assist you."
    }
  },{
    "@type": "Question",
    "name": "What payment methods do you accept?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We accept various payment methods, including credit cards, online payments, and more."
    }
  },{
    "@type": "Question",
    "name": "What types of payment plans do you offer?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We provide various payment plans to suit your budget, including one-time payments, installment plans, and financing options."
    }
  },{
    "@type": "Question",
    "name": "What if I have an emergency repair situation?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "In case of an emergency repair situation, please contact us immediately. We're here to help with towing and on-site services."
    }
  },{
    "@type": "Question",
    "name": "What are your operating hours?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our service centres operate during specific hours; please refer to our website for detailed hours of operation."
    }
  },{
    "@type": "Question",
    "name": "Do you offer fleet maintenance services for businesses?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "While we don't currently offer fleet maintenance services for businesses, we're planning to launch this program in the near future. Stay tuned for updates, and we'll be happy to serve your fleet maintenance needs soon."
    }
  },{
    "@type": "Question",
    "name": "How can I stay updated on industry news and tips?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Check our blog and newsletters for the latest industry news, maintenance tips, and updates on new services."
    }
  }]
}`}
        </script>
      </Helmet>

      {iseLoading && <Loader />}
      <Banner bannerData={bannerData} allCategoriesData={allCategoriesData} />
      <ProfessionalDenting dentPent={proDentPent} />
      <ExperianceIndustry scrollToChild={scrollToChild} grow={grow} />

      <WorkProcess scrollToChild={scrollToChild} workProcess={workProcess} />
      {partners.length && (
        <Brands brand={partners} maintext="Products" text="We Use" />
      )}
      <BestWork socialMediaData={socialMediaData} />
      <Testimonial testimonials={testimonialsData} />
      <DownLoadApp />
      <Brands brand={brands} maintext="Brands" text=" We Work With" />

      <ContactUs childRef={childRef} />
      <FrequentalyAskQuestions />
    </>
  );
}

export default Homepage;
