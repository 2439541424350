import React from "react";
import TitleName from "./TitleName";
import "./CommonCeramic.css";
import handcoating from "../../asets/Rectangle 1.png";
import handcoating1 from "../../asets/Rectangle 2.png";
import handcoating2 from "../../asets/Rectangle 3.png";

const BenfitsData = () => {
  return (
    <div className="mt-3">
      <div
        class="card w-100 p-3"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "none",
        }}
      >
        <div className="p-4">
          <h3 class="card-title text-danger fw-bold">Benefits</h3>
          <p className="fs-5 mt-2">
            <strong>Long-Lasting Protection:</strong> Ceramic coating provides a
            durable protective layer that significantly extends the life of your
            car's paint. This shield guards against various environmental
            contaminants like bird droppings, tree sap, and road salts,
            preventing them from embedding into the paint and causing damage
            over time.
          </p>
          <p className="fs-5 mt-3">
            <strong>Enhanced Gloss and Shine:</strong> One of the standout
            features of ceramic coatings is that it gives your car a superior,
            glossy finish. This vibrant shine gives your car a showroom-like
            appearance, turning heads wherever you go.
          </p>
          <p className="fs-5 mt-3">
            <strong>Scratch and Swirl Resistance:</strong> These coatings are
            formulated to resist minor scratches and swirl marks that can occur
            during regular washing and drying.
          </p>
          <p className="fs-5 mt-3">
            <strong>Cost-Effective in the Long Run:</strong> Reduces the need
            for frequent waxing, polishing, and detailing, ceramic coatings save
            your money in the long run.
          </p>
          <p className="fs-5 mt-3">
            <strong>Chemical Resistance:</strong> Shields against chemicals and
            pollutants, including harsh detergents, acidic rain, and industrial
            fallout. This resistance ensures that your car’s paint remains
            unaffected by substances that would normally cause damage or dull
            its finish.
          </p>
          <p className="fs-5 mt-3">
            <strong>UV and Oxidation Resistance:</strong> Over time, exposure to
            the sun's UV rays can cause your car's paint to fade and oxidize,
            leading to a dull and lifeless appearance. It contains UV blockers
            that help protect your paint from this damage.
          </p>
          <p className="fs-5 mt-3">
            <strong>Ease of Cleaning:</strong> One of the most practical
            benefits is how it makes cleaning your car much easier. The smooth,
            slick surface created by the coating helps prevent dirt and debris
            from clinging to your car, keeping it cleaner for longer.
          </p>
        </div>
      </div>
    </div>
  );
};

const CeramicBenfits = () => {
  return (
    <div className="container mb-3">
      <TitleName title="BENEFITS OF" subtitle="CERAMIC COATING" />
      <div className="row">
        <div className="col-md-7">
          <img className="w-100" src={handcoating} alt="Ceramic Coating for Cars" />
        </div>
        <div className="col-md-5">
          <img
            // style={{ width: "470px", height: "292px" }}
            className="w-100 mt-1"
            src={handcoating2}
            alt="Ceramic Coating Application"
          />
          <img
            // style={{ width: "475px", height: "292px" }}
            className="w-100 mt-3"
            src={handcoating1}
            alt="Ceramic Coating Finishing"
          />
        </div>
      </div>
      <BenfitsData />
    </div>
  );
};

export default CeramicBenfits;
