import React from "react";
import "./Common.css";

const InstallationProcess = () => (
  <div className="container">
    <div className="installation-process">
      <h2 className="text-center text-danger mt-4 fw-bold uppercase">
        Why choose Caross for PPF services in Delhi?
      </h2>
      <div className="row mt-4">
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step purchase-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>1.Expert Technicians</h3>
            <p>
              Our technicians are experienced and knowledgeable in applying PPF
              to various vehicle types. They use the latest techniques to ensure
              a perfect fit and finish.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step call-scheduled-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>2. High-Quality Materials</h3>
            <p>
              We use only the best PPF materials from trusted brands, ensuring
              durability and long-lasting protection for your vehicle.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step install-test-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>3.Affordable Pricing</h3>
            <p>
              Quality protection shouldn’t break the bank. We offer competitive
              pricing on all our PPF services, giving you the best value for
              your money.
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mt-2">
          <div
            className="installation-process-step finished-step"
            style={{ width: "100%", height: "100%" }}
          >
            <h3>4.Customer Satisfaction</h3>
            <p>
              At GeekLane, customer satisfaction is our top priority. We go the
              extra mile to ensure you’re happy with the results, providing
              aftercare support and maintenance tips to keep your vehicle
              looking its best.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InstallationProcess;
