import React from "react";
import "./CommonCarDetailing.css";

const WhatCarDetailing = () => {
  return (
    <>
      <div className="mb-2 p-2">
        <div className="row">
          <div className="col-md-5">
            <h2 className="mt-4 fw-bold ">
              INTRODUCATION TO{" "}
              <span className="text-uppercase" style={{ color: "#b8242a" }}>
                Car Detailing Services
              </span>{" "}
            </h2>
            <h2 className="fw-bold mt-4">What Are Care Detailing Services?</h2>
            <p>
              Car detailing services involve cleaning and restoring your vehicle
              to keep it looking good and in great condition. Unlike a regular
              car wash that mainly cleans the outside, car detailing gives both
              the inside and outside of your car a deep and thorough clean. It
              also includes small repair tasks that help improve your car’s
              appearance and make it last longer.
            </p>

            <h2 className="fw-bold mt-4">Why Car Detailing Is Important in Delhi</h2>
            <p>
              In DELHI, car detailing is essential due to the city’s high
              levels of dust, pollution, and variable weather conditions.
              Regular detailing helps protect your car’s exterior from harmful
              elements that can cause paint damage and rust. Detailing also
              improves your driving experience by keeping your car’s windows,
              mirrors, and headlights clear for better visibility.
            </p>
          </div>
          <div className="col-md-5 image-container">
            <img
              className="w-100 mt-2 image-zoom rounded"
              src="https://lirp.cdn-website.com/263ba0d9/dms3rep/multi/opt/ceramic-coating-detail-solution-06-640w.jpg"
              alt="Introduction to Ceramic Coating"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatCarDetailing;
