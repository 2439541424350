import React from "react";
import "./Common.css";
// import InTwo from "../../../src/asets/InTwo.jpg";
import InTwo from "../../../asets/InTwo.jpg";
import InSeven from "../../../asets/InSeven.jpg";
import InThree from "../../../asets/InThree.jpg";
import InFour from "../../../asets/InFour.jpg";
import InNine from "../../../asets/InNine.jpg";
import InTen from "../../../asets/InTen.jpg";

const InstagramImages = () => {
  const imageSources = [InSeven, InTwo, InFour, InThree, InNine, InTen];

  const instagramLinks = [
    "https://www.instagram.com/p/C9KPMgUv4KW/page1",
    "https://www.instagram.com/reel/C7yzmhOP3Ll/page2",
    "https://www.instagram.com/p/C9PvtLXPbya/page3",
    "https://www.instagram.com/reel/C86QwWrPPv_/page4",
    "https://www.instagram.com/reel/C7gx-uqvrHw/page5",
    "https://www.instagram.com/reel/C8eeNTMNMZH/page6",
  ];

  return (
    <div className="mt-4">
      <p className="text-center">OUR INSTAGRAM FEED</p>
      <h2 className="text-center text-danger fw-bold">INSTAGRAM</h2>
      <div className="d-flex flex-wrap justify-content-center my-3">
        {imageSources.map((src, index) => (
          <div key={index} className="card mx-3 my-2">
            <a
              href={instagramLinks[index]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={src}
                className="card-img-top zoom-on-hover"
                alt={`Instagram ${index + 1}`}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstagramImages;
