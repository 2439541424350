import React from "react";
import TitleName from "./TitleName";

const CarDetailingFaq = () => {
  return (
    <div className="container mt-3">
      <TitleName title="Frequently Asked " subtitle="Questions (FAQs)" />
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h3 className="fs-5">
                Why is car detailing especially important in Gurgaon?
              </h3>
            </button>
          </h3>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Car detailing is crucial in Gurgaon due to the high levels of
                dust and pollution. Regular detailing helps protect your car’s
                paint from dust damage and keeps the interior clean from fine
                particles.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <h3 className="fs-5">
                {" "}
                Can car detailing remove stains from my car’s interior?
              </h3>
            </button>
          </h3>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Yes, detailing services include stain removal for seats,
                carpets, and upholstery. We use special cleaners to treat and
                remove stains, helping to keep your interior looking fresh.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h3 className="fs-5">
                Are there specific detailing services for cars affected by
                pollution?
              </h3>
            </button>
          </h3>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Yes, our detailing services are designed to handle the effects
                of pollution. We offer deep cleaning and protective treatments
                to combat pollutants and keep your car in good condition.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5"> Can detailing remove stains caused by Gurgaon’s weather?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
              Yes, detailing can remove stains and contaminants caused by the weather. Our services include special treatments to clean and protect your car’s paint and interior.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5"> How long does a detailing service take?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                The time required for detailing depends on the service package
                you choose and the condition of your car. On average, it can
                take anywhere from 2 to 4 hours.
              </p>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default CarDetailingFaq;
