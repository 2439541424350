import React from "react";
import "./Common.css";
import paintProtectionOne from "../../../asets/paintProtectionOne.png";

const PaintProtection = () => {
  return (
    <div className="container mt-3 mb-3">
      <div className="row mt-4">
        <h1 className="text-center text-danger mt-5 fw-bold text-uppercase">
          PPF Services in Delhi
        </h1>
        <div className="col-md-6 mt-3 d-flex justify-content-center align-items-center">
          <img
            src={paintProtectionOne}
            alt="Car Paint Protection Film"
            className="img-fluid w-100"
          />
        </div>
        <div className="col-md-6 mt-3 d-flex justify-content-center align-items-center">
          <div className="accordion w-100 newaccordions" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button personalaccrodian"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h2 style={{ fontSize: "18px" }}>
                    Why choose PPF for your vehicle in Delhi?
                  </h2>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Your vehicle is more than just a mode of transport—it's an
                  investment{""}
                  <strong style={{ fontSize: "18px" }}>
                    {" "}
                    The roads in Delhi can be harsh on your car's exterior, with
                    exposure to pollution, extreme weather, and everyday
                    scratches.
                  </strong>
                  <span style={{ fontSize: "17.5px" }}>
                    That's where Paint Protection Film (PPF) comes in. At
                    GeekLane, we offer high-quality PPF services that act as an
                    invisible shield, keeping your vehicle looking new for years
                    to come.
                  </span>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  style={{ backgroundColor: "#b8242a", color: "#fff" }}
                  className="accordion-button collapsed personalaccrodian"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h2 style={{ fontSize: "18px" }}>
                    Why is PPF Essential for Your Car in Delhi NCR?
                  </h2>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong style={{ fontSize: "18px", fontWeight: "500" }}>
                    In the bustling environment of Delhi NCR, your car faces
                    numerous challenges.
                  </strong>
                  <span
                    style={{ fontSize: "17.5px", letterSpacing: "0.04rem" }}
                  >
                    From harsh weather conditions, pollution, and heavy traffic
                    to minor accidents and road debris,
                    <strong>
                      your car's paintwork is constantly under threat.
                    </strong>
                    PPF provides the essential protection needed to keep your
                    car in pristine condition amidst these urban challenges.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaintProtection;
