import React from "react";
import TitleName from "./TitleName";
import "./CommonCeramic.css";
import handcoating from "../../../asets/Rectangle 1.png";
import handcoating1 from "../../../asets/Rectangle 2.png";
import handcoating2 from "../../../asets/Rectangle 3.png";

const BenfitsData = () => {
  return (
    <div className="mt-3">
      <div
        class="card w-100 p-3"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "none",
        }}
      >
        <div className="p-4">
          <h3 class="card-title text-danger fw-bold">Benefits</h3>
          <p className="fs-5 mt-2">
            <strong>Enhanced Protection Against Pollution:</strong> Gurgaon’s
            high pollution levels expose your vehicle to harmful contaminants
            and acid rain. Ceramic coating acts as a protective barrier,
            preventing pollutants from adhering to the paint and causing damage.
          </p>
          <p className="fs-5 mt-3">
            <strong> Shield from Extreme Weather Conditions:</strong> These
            coatings provide a robust layer of protection against UV rays,
            preventing paint oxidation and fading, while also guarding against
            water damage and corrosion from rain and moisture.
          </p>
          <p className="fs-5 mt-3">
            <strong> Hydrophobic Properties for Easy Cleaning:</strong> The
            hydrophobic nature of these coatings cause water, dirt, and grime to
            slide off more easily. This makes washing your vehicle quicker and
            more efficient, reducing the need for frequent detailing.
          </p>
          <p className="fs-5 mt-3">
            <strong>Long-Lasting Gloss and Shine</strong> It enhances the depth
            and clarity of your vehicle’s paint, providing a glossy,
            showroom-quality finish.
          </p>
          <p className="fs-5 mt-3">
            <strong>Increased Scratch Resistance:</strong> While not entirely
            scratch-proof, this coating adds a protective layer that makes your
            car’s paint more resistant to minor scratches and swirl marks.
          </p>
          <p className="fs-5 mt-3">
            <strong> Protection Against Chemical Stains:</strong> Gurgaon’s
            roads can be littered with chemical spills and road salts. It
            provides a protective layer that resists chemical stains, preventing
            potential damage.
          </p>
        </div>
      </div>
    </div>
  );
};

const CeramicBenfits = () => {
  return (
    <div className="container mb-3">
      <TitleName
        title="Top benefits of"
        subtitle="Ceramic Coating in Gurgaon"
      />
      <div className="row">
        <div className="col-md-7">
          <img
            className="w-100"
            src={handcoating}
            alt="Ceramic Coating for Cars"
          />
        </div>
        <div className="col-md-5">
          <img
            // style={{ width: "470px", height: "292px" }}
            className="w-100 mt-1"
            src={handcoating2}
            alt="Ceramic Coating Application"
          />
          <img
            // style={{ width: "475px", height: "292px" }}
            className="w-100 mt-3"
            src={handcoating1}
            alt="Ceramic Coating Finishing"
          />
        </div>
      </div>
      <BenfitsData />
    </div>
  );
};

export default CeramicBenfits;
