import React from "react";
import "./Common.css";
import paintProtectionOne from "../../../asets/paintProtectionOne.png";

const PaintProtection = () => {
  return (
    <div className="container mt-3 mb-3">
      <div className="row mt-4">
        <h1 className="text-center text-danger mt-5 fw-bold">
          PPF SERVICES IN GURUGRAM
        </h1>
        <div className="col-md-6 mt-3 d-flex justify-content-center align-items-center">
          <img
            src={paintProtectionOne}
            alt="Car Paint Protection Film"
            className="img-fluid w-100"
          />
        </div>
        <div className="col-md-6 mt-3 d-flex justify-content-center align-items-center">
          <div className="accordion w-100 newaccordions" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button personalaccrodian"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h2 style={{ fontSize: "18px" }}>What are PPF services?</h2>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong style={{ fontSize: "18px" }}>
                    PPF (Paint Protection Film) services involve applying a
                    transparent,
                  </strong>
                  <span style={{ fontSize: "17.5px" }}>
                    durable film to your vehicle's exterior to protect the paint
                    from scratches, chips, and other damage.This film acts as a
                    shield against road
                    <strong>
                      {" "}
                      debris, UV rays, and environmental contaminants,
                    </strong>
                    , keeping your car's paint in pristine condition while
                    maintaining its original appearance.
                  </span>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  style={{ backgroundColor: "#b8242a", color: "#fff" }}
                  className="accordion-button collapsed personalaccrodian"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h2 style={{ fontSize: "18px" }}>
                    Why is PPF essential for your car in Gurgaon?
                  </h2>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong style={{ fontSize: "18px", fontWeight: "500" }}>
                  PPF (Paint Protection Film) is essential for your car in Gurgaon due to the 
                  </strong>
                  <span
                    style={{ fontSize: "17.5px", letterSpacing: "0.04rem" }}
                  >
                    <strong> city's harsh driving conditions, including heavy traffic, pollution, and extreme weather. 
                    </strong> 
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaintProtection;
