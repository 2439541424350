import React from "react";
import TitleName from "./TitleName";

const CarDetailingFaq = () => {
  return (
    <div className="container mt-3">
      <TitleName title="Frequently Asked " subtitle="Questions (FAQs)" />
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h3 className="fs-5">What is car detailing?</h3>
            </button>
          </h3>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Car detailing is a thorough cleaning and restoration process
                that makes your car look and feel like new. It involves cleaning
                both the interior and exterior of your car, and sometimes
                includes minor repairs to improve its appearance.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <h3 className="fs-5"> How often should I get my car detailed?</h3>
            </button>
          </h3>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                It depends on how often you use your car and its condition.
                Generally, getting your car detailed every 4 to 6 months is a
                good idea to keep it in top shape.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h3 className="fs-5">
                What is included in an exterior detailing service?
              </h3>
            </button>
          </h3>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Exterior detailing typically includes a hand wash, clay bar
                treatment, polishing, waxing, and tire cleaning. This helps
                remove dirt, restore shine, and protect the paint.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5"> What does interior detailing involve?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                Interior detailing includes vacuuming the seats, carpets, and
                mats, cleaning and conditioning leather, and wiping down
                surfaces like the dashboard and console. It also involves
                removing any odors from the interior.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="accordion-item">
          <h3 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <h3 className="fs-5"> How long does a detailing service take?</h3>
            </button>
          </h3>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="fs-6">
                The time required for detailing depends on the service package
                you choose and the condition of your car. On average, it can
                take anywhere from 2 to 4 hours.
              </p>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default CarDetailingFaq;
