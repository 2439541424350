import React from "react";
import WhatCoating from "./WhatCoating";
import CeramicBenfits from "./CeramicBenfits";
import CeramicCoatingOptions from "./CeramicCoatingOptions";
import WhyChoose from "./WhyChoose";
import CeramicFaq from "./CeramicFaq";
import ContactUs from "../../homepage/contact-us/ContactUs";
import CeramicTestimonials from "./CeramicTestimonials";
import CeramicSocial from "./CeramicSocial";
import { Helmet } from "react-helmet";

const CeramicCoatingServicesGgn = () => {
  return (
    <>
      <Helmet>
        <title> Ceramic Coating in Gurgaon by Caross | Long-lasting Shine</title>
        <meta
          name="description"
          content="Protect your car with top-notch ceramic coating in Gurgaon by Caross. Experience long-lasting shine, UV protection, and scratch resistance with expert services."
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "How Long Does Ceramic Coating Last?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Our ceramic coatings typically last several years with proper care.",
                },
              },
              {
                "@type": "Question",
                name: "Can Ceramic Coating be Removed?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, but it requires professional removal.",
                },
              },
              {
                "@type": "Question",
                name: "Does Ceramic Coating Affect the Car's Appearance?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, it enhances the appearance with a deep, glossy finish.",
                },
              },
              {
                "@type": "Question",
                name: "Is Ceramic Coating Worth the Investment?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Absolutely, it offers long-term protection and enhances your car’s value.",
                },
              },
            ],
          }`}
        </script>
      </Helmet>
      <div className="p-2">
        <h1 className="text-center fw-bold" style={{ marginTop: "5rem" }}>
          CERAMIC <span  className="text-uppercase" style={{ color: "#b8242a" }}>Coating Services in Gurgaon</span>
        </h1>
        <WhatCoating />
        <CeramicBenfits />
        <CeramicCoatingOptions />
        <WhyChoose />
        <CeramicFaq />
        <CeramicTestimonials />
        <CeramicSocial />
        <ContactUs />
      </div>
    </>
  );
};

export default CeramicCoatingServicesGgn;
