import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Toast from "../../Tost";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import StoreNavbar from "./StoreNavbar";
import Loader from "../../Loader";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StoreManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { address } = location.state || {};
  const state = Cookies.get("state");
  const [orders, setOrders] = useState([]);
  const [formData, setFormData] = useState({
    date: "",
    item: "",
    quantity: "",
    price: "",
    gst: "",
    total: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!Cookies.get("storeToken")) {
      navigate("/store-login");
    } else {
      fetchOrders();
    }
  }, []);

  const fetchOrders = async () => {
    const token = Cookies.get("storeToken");
    try {
      setIsLoading(true);
      const response = await axios.get(
        "https://caross.in/admin/api/user/get-store-products",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.data;

        const currentDate = new Date().toISOString().split('T')[0];

        // Filter orders by current date and others
        const currentDateOrders = data.filter(order => order.date === currentDate);
        const otherOrders = data.filter(order => order.date !== currentDate);

        // Sort current date orders by time if time is present
        currentDateOrders.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Sort other orders by date in descending order
        otherOrders.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Combine current date orders on top followed by other orders
        const sortedData = [...currentDateOrders, ...otherOrders];

        setOrders(sortedData);
    }
    } catch (err) {
      console.error("Failed to fetch orders", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [id]: value,
      };

      if (["price", "gst", "quantity"].includes(id)) {
        const { quantity, price, gst } = updatedData;
        if (quantity && price && gst) {
          const total = quantity * price + (quantity * price * gst) / 100;
          updatedData.total = total.toFixed(2);
        }
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { date, item, quantity, price, gst, total } = formData;
    const token = Cookies.get("storeToken");

    if (!date || !item || !quantity || !price || !gst || !total) {
      setError("Please fill all fields before submitting");
      return;
    }

    try {
      setIsLoading(true);
      await axios.post(
        "https://caross.in/admin/api/user/add-store-product",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Close the modal
      document.getElementById("closeModalButton")?.click();

      // Reset form and fetch updated orders
      setFormData({
        date: "",
        item: "",
        quantity: "",
        price: "",
        gst: "",
        total: "",
      });
      fetchOrders();
    } catch (error) {
      console.log("Something went wrong", error);
      Toast("Failed to send data");
    } finally {
      setIsLoading(false);
    }
  };

  const calculateTotalAmount = () => {
    return orders
      .filter((order) => {
        if (startDate && endDate) {
          const orderDate = new Date(order.date);
          return orderDate >= startDate && orderDate <= endDate;
        }
        return true;
      })
      .reduce((sum, order) => sum + parseFloat(order.total), 0)
      .toFixed(2);
  };

  const downloadExcel = () => {
    const filteredOrders = orders.filter((order) => {
      if (startDate && endDate) {
        const orderDate = new Date(order.date);
        return orderDate >= startDate && orderDate <= endDate;
      }
      return true;
    });

    const ws = XLSX.utils.json_to_sheet([
      ...filteredOrders,
      { item: "Total", total: calculateTotalAmount() },
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");
    XLSX.writeFile(wb, "Orders.xlsx");
    setStartDate(null);
    setEndDate(null);
  };
  const isExcelDisable=()=>{
    if(!startDate && endDate)return true;
    if(startDate && !endDate)return true;
    return false;
  }
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <>
      {isLoading && <Loader />}
      <StoreNavbar />
      <div className="container mt-5 pt-5">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="text-danger fw-bold">Welcome to {state}</h4>
          <div>
            <button
              type="button"
              className="btn me-2"
              data-bs-toggle="modal"
              data-bs-target="#orderModal"
              style={{ backgroundColor: "#b8242a", color: "white" }}
            >
              <i className="bi bi-plus-circle"></i> Expenses
            </button>
            <button
              type="button"
              className="btn"
              onClick={downloadExcel}
              disabled={isExcelDisable()}
              style={{ backgroundColor: "#28a745", color: "white" }}
            >
              <i className="bi bi-download"></i> Excel
            </button>
          </div>
        </div>

        <div className="mt-4">
          <h5>Filter by Date Range</h5>
          <div className="d-flex">
            <DatePicker
              selected={startDate}
             dateFormat="dd-MM-yyyy"
              onChange={(date) => setStartDate(date)}
              placeholderText="Start Date"
              className="form-control me-2"
            />
            <DatePicker
              selected={endDate}
               dateFormat="dd-MM-yyyy"
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
              className="form-control"
            />
          </div>
        </div>

        <div
          className="modal fade"
          id="orderModal"
          tabIndex="-1"
          aria-labelledby="orderModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ marginTop: "6rem" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="orderModalLabel">
                  Add Expenses
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModalButton"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="item" className="form-label">
                        Item Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="item"
                        placeholder="Enter Item Name"
                        value={formData.item}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="date" className="form-label">
                        Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        value={formData.date}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="quantity" className="form-label">
                        Quantity
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="quantity"
                        placeholder="Enter Quantity"
                        value={formData.quantity}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="price" className="form-label">
                        Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="price"
                        placeholder="Enter Price"
                        value={formData.price}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="gst" className="form-label">
                        GST (%)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="gst"
                        placeholder="Enter GST"
                        value={formData.gst}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3 w-100">
                      <label htmlFor="total" className="form-label">
                        Total Amount
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="total"
                        value={formData.total}
                        readOnly
                        required
                      />
                    </div>
                  </div>
                  {error && <p className="text-danger">{error}</p>}
                  <button
                    type="submit"
                    className="btn"
                    style={{ backgroundColor: "#b8242a", color: "white", float: "right"  }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Saving..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
        <table className="table mt-4">
          <thead>
            <tr>
              <th>Date</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>GST</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {orders
              .filter((order) => {
                if (startDate && endDate) {
                  const orderDate = new Date(order.date);
                  return orderDate >= startDate && orderDate <= endDate;
                }
                return true;
              })
              .map((order, index) => (
                <tr key={index}>
                  <td>{formatDate(order.date)}</td>
                  <td>{order.item}</td>
                  <td>{order.quantity}</td>
                  <td>{order.price}</td>
                  <td>{`${order.gst} %`}</td>
                  <td>{order.total}</td>
                </tr>
              ))}
            <tr className="fw-bold">
              <td colSpan="5" className="text-end">
                Total Amount:
              </td>
              <td>{calculateTotalAmount()}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>

    </>
  );
};

export default StoreManagement;
