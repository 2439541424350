import React from "react";
import TitleName from "./TitleName";
import { Link } from "react-router-dom";


const WhyChoose = () => {
  return (
    <div className="container">
      
      <div className="row">
        <div className="col-md-6">
          <TitleName
            title="Why Choose Caross "
            subtitle=" for ceramic coating in Gurgaon?"
          />

          <div>
            <p className="fs-5 mt-2">
              <span className="fw-bold">Expert application:</span> Our skilled
              technicians are trained in the latest techniques for applying
              ceramic coatings, ensuring a flawless finish and optimal
              protection for your vehicle.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">Premium products:</span> We use
              high-grade ceramic coatings from leading brands, guaranteeing
              durability and effectiveness.
            </p>
            {/* <p className="fs-5 mt-2">
              <span className="fw-bold">Customized solutions:</span> We offer a
              range of packages to suit your vehicle’s needs, whether you’re
              looking for full coverage or targeted protection.
            </p> */}
            <p className="fs-5 mt-2">
              <span className="fw-bold">Competitive pricing:</span> Our services
              are priced competitively, offering exceptional value without
              compromising on quality.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">Customer satisfaction:</span> We are
              dedicated to ensuring you are delighted with our services. From
              initial consultation to final application, we prioritize your
              satisfaction.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <TitleName
            title="The Ceramic Coating "
            subtitle="Application Process"
          />

          <div>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Initial Consultation and Assessment:
              </span>{" "}
              We assess your vehicle’s condition and discuss your needs.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Preparing Your Car for Ceramic Coating:{" "}
              </span>{" "}
              Thorough cleaning and surface preparation.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Applying the Coating: Step-by-Step Guide:{" "}
              </span>{" "}
              Detailed application process to ensure even coverage.
            </p>
            <p className="fs-5 mt-2">
              <span className="fw-bold">
                Post-Application Care and Maintenance:{" "}
              </span>{" "}
              Tips and guidelines to maintain your car’s new look.
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Link to="/services/car-paint-protection">
          <button
            style={{
              backgroundColor: "#b42424",
              color: "#fff",
              padding: "13px 50px",
              fontSize: "15px",
            }}
            className="btn btn mt-5"
          >
            Book Your Ceramic Coating Consultation Now
          </button>
        </Link>
      </div>
     
    </div>
  );
};

export default WhyChoose;
