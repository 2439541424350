import React from "react";

const FAQ = () => {
  return (
    <div>
      <h2 className="text-center text-danger mt-5 fw-bold">
        FREQUENTLY ASKED QUESTION (FAQ)
      </h2>
      <div className="mt-3">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h3 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                1.Is PPF really necessary for my car in Gurgaon?
              </button>
            </h3>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, PPF is highly recommended for car owners in Gurgaon. The
                city’s heavy traffic, pollution, and extreme weather conditions
                can quickly damage your car’s paint.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                2. Can PPF be applied to specific parts of my car, or does it
                need full coverage?
              </button>
            </h3>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                PPF can be applied to specific high-impact areas such as the
                hood, front bumper, mirrors, and door edges, or you can opt for
                full-body coverage for comprehensive protection.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                3. Is PPF self-healing, and what does that mean?
              </button>
            </h3>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, many high-quality PPF films have self-healing properties.
                This means that minor scratches and swirl marks on the film can
                disappear on their own when exposed to heat, such as sunlight or
                warm water, keeping your car’s surface smooth and flawless.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                4.How much does PPF installation cost in Gurgaon?
              </button>
            </h3>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                The cost of PPF installation varies depending on the size of
                your vehicle, the quality of the film, and the extent of
                coverage you choose.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
