import React from "react";
import TitleName from "./TitleName";
import "./CommonCarDetailing.css";
import handcoating from "../../../asets/Rectangle 1.png";
import handcoating1 from "../../../asets/Rectangle 2.png";
import handcoating2 from "../../../asets/Rectangle 3.png";

const BenfitsData = () => {
  return (
    <div className="mt-3">
      <div
        class="card w-100 p-3"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "none",
        }}
      >
        <div className="p-4">
          <h3 class="card-title text-danger fw-bold">Benefits</h3>
          <p className="fs-5 mt-2">
            <strong>Protects Your Car’s Exterior:</strong> Gurgaon’s weather can
            be harsh, with dust and pollution affecting your car’s paint.
          </p>
          <p className="fs-5 mt-3">
            <strong>Maintains the Interior:</strong> Daily use and the dusty
            environment in Gurgaon can lead to a buildup of dirt inside your
            car. Detailing cleans the interior thoroughly, which helps prevent
            wear and keeps it looking fresh.
          </p>
          <p className="fs-5 mt-3">
            <strong>Increases Resale Value:</strong> A well-maintained car is
            worth more if you decide to sell it. Regular detailing helps keep
            your car in excellent condition, making it more attractive to
            buyers.
          </p>
          <p className="fs-5 mt-3">
            <strong>Prevents Long-Term Damage:</strong> Regular detailing
            removes dirt and grime that can cause long-term damage to your car’s
            exterior and interior. This helps prevent issues like rust and
            fading.
          </p>
          <p className="fs-5 mt-3">
            <strong>Enhances Your Driving Experience:</strong> A clean car is
            more enjoyable to drive. Detailing ensures that both the inside and
            outside of your car are in great shape, making your time on the road
            more pleasant.
          </p>
          <p className="fs-5 mt-3">
            <strong>Boosts Safety:</strong> Clean headlights, mirrors, and
            windows improve visibility while driving. Detailing ensures that all
            these parts are spotless, contributing to safer driving conditions.
          </p>
        </div>
      </div>
    </div>
  );
};

const BenifitCarDetailing = () => {
  return (
    <div className="container mb-3">
      <TitleName title="BENEFITS OF" subtitle="Car Detailing Services" />
      <div className="row">
        <div className="col-md-7">
          <img
            className="w-100"
            src={handcoating}
            alt="Ceramic Coating for Cars"
          />
        </div>
        <div className="col-md-5">
          <img
            // style={{ width: "470px", height: "292px" }}
            className="w-100 mt-1"
            src={handcoating2}
            alt="Ceramic Coating Application"
          />
          <img
            // style={{ width: "475px", height: "292px" }}
            className="w-100 mt-3"
            src={handcoating1}
            alt="Ceramic Coating Finishing"
          />
        </div>
      </div>
      <BenfitsData />
    </div>
  );
};

export default BenifitCarDetailing;
